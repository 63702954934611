const colorMap = {
  marineBlue: "#002c5f",
  blue: "#1c20b8",
  gray700: "#434343",
  gray600: "#676767",
  gray500: "#999999",
  gray300: "#d7d7d7",
  ivory: "#f6f3f2",
  beige: "#e4dcd3",
  black: "#1d1b1b",
  blue100: "#e6eaef",
  red: "#d14428",
  skyblue: "#00aad2",
} as const;

export function getColor(key: keyof typeof colorMap) {
  return colorMap[key];
}
