import Dialog from "@/components/shared/Dialog";
import { ReactNode, createContext, useContext, useState } from "react";

interface Props {
  placeholderImages: { base64: string }[];
}

export const placeholderContext = createContext<Props>({ placeholderImages: [] });

export const PlaceHolderProvider = ({
  children,
  placeholderImages: pis,
}: {
  children: ReactNode;
  placeholderImages: { base64: string }[];
}) => {
  const [placeholderImages] = useState(pis);
  return <placeholderContext.Provider value={{ placeholderImages }}>{children}</placeholderContext.Provider>;
};

export const usePlaceholder = () => useContext(placeholderContext);
