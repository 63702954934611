import LocalStorage from "@/utils/localstorage";
import { instance } from ".";
import SessionStorage from "@/utils/sessionstorage";

interface RegisterParams {
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  mainJob: string;
  subJob: string;
  region: string;
  country: string;
  subscribe: boolean;
  password: string;
}
export const register = (params: RegisterParams) => {
  return instance.post("/auth/sign", params);
};

export const login = (params: { email: string; password: string }) => {
  return instance.post("/auth/login", params);
};

export const changePassword = (params: { password: string; newPassword: string }) => {
  return instance.patch("/users/password", params);
};

export const refresh = async () => {
  if (!SessionStorage.getItem("hmc_refresh_token") || !SessionStorage.getItem("hmc_access_token")) return;

  try {
    const { data } = await instance.post("/auth/refresh", {
      refreshToken: SessionStorage.getItem("hmc_refresh_token"),
      accessToken: SessionStorage.getItem("hmc_access_token"),
    });
    SessionStorage.setItem("hmc_refrech_token", data.refreshToken);
    SessionStorage.setItem("hmc_access_token", data.accessToken);
  } catch (e) {
    SessionStorage.removeItem("hmc_refresh_token");
    SessionStorage.removeItem("hmc_access_token");
    throw e;
  }
};

export interface AuthInfo {
  _id: string;
  country: string;
  createdAt: string;
  email: string;
  failedLoginCount: number;
  firstName: string;
  lastLoginDate: string;
  lastName: string;
  mainJob: "Sales" | "Services" | "Others";
  nickname: "string";
  region: string;
  role: "user" | "admin";
  subJob: string;
  subscribe: boolean;
  subscribedAt: boolean;
  updatedAt: string;
  verified: string;
}
export const getAuthInfo = () => {
  return instance.get<AuthInfo>("/users/me");
};

export const updateAuthInfo = (params: Partial<Omit<RegisterParams, "password">>) => {
  return instance.patch("/users", params);
};

export const deleteUser = () => {
  return instance.delete("/users");
};

export const resetPassword = (email: string) => {
  return instance.get(`/users/password/reset?email=${email}`);
};

export const getDuplicate = (type: "email" | "nickname", value: string) => {
  return instance.get(`/users/existence?type=${type}&value=${value}`);
};
