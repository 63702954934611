import "@/styles/globals.css";
import dynamic from 'next/dynamic';
import type { AppContext, AppProps } from "next/app";
import { useTranslation } from "react-i18next";
import { PaletteColorOptions, ThemeProvider, createTheme } from "@mui/material";
import { getColor } from "@/utils/style";
import Script from "next/script";
import Checked from "../assets/checked.svg";
import Checkbox from "../assets/checkbox.svg";
import { AuthProvider } from "@/contexts/authContext";
import { AlertProvider } from "@/contexts/alertContext";
import { useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider, dehydrate } from "@tanstack/react-query";
import { RecoilRoot } from 'recoil';
import LocalStorage from "@/utils/localstorage";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import AsyncBackend from "i18next-async-backend";
// import CookieAgree from "@/components/Cookie/CookieAgree";
const CookieAgree = dynamic(() => import('@/components/Cookie/CookieAgree'), { ssr: false, suspense: false });
import ExpandMore  from "@mui/icons-material/ExpandMore";
import { isMobile } from "@/utils/userAgent";
import Head from "next/head";
import { PlaceHolderProvider } from "@/contexts/placeholderContext";
import { getWebzines } from "@/apis/webzine";

declare module "@mui/material/styles" {
  interface CustomPalette {
    black: PaletteColorOptions;
    marineBlue: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
    marineBlue: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1364,
      xl: 1600,
    },
  },
  palette: {
    black: {
      main: "#171719",
    },
    marineBlue: {
      main: getColor("marineBlue"),
    },
  },
  typography: {
    fontFamily: "hyundaisans",
    allVariants: {
      lineHeight: 1,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiSwitch: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          width: "6rem",
          height: "3rem",
          borderRadius: "8px",
          color: "#e6e6e6",
          background: "#e6e6e6",
          "& .MuiSwitch-track": {
            background: "#e6e6e6 !important",
          },
          "& .MuiSwitch-switchBase": {
            padding: 0,
            width: "3rem",
            height: "3rem",
            borderRadius: "8px",
            color: getColor("gray500"),
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(30px)",
          },
          "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: getColor("skyblue"),
            backgroundColor: getColor("skyblue"),
          },
          "& .MuiSwitch-input": {
            width: "200%",
          },
          "& .MuiSwitch-thumb": {
            padding: 0,
            width: "3rem",
            height: "3rem",
            borderRadius: "8px",
            backgroundColor: getColor("gray500"),
            boxShadow: "none",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 500,
          color: getColor("gray500"),
          "& .Mui-selected": {
            backgroundColor: "white !important",
            color: getColor("marineBlue"),
            fontWeight: 600,
            pointerEvents: "none",
            "&:hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
        icon: <Checkbox viewBox="0 0 18 18" />,
        checkedIcon: <Checked viewBox="0 0 18 18" />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          minWidth: "1.8rem",
          minHeight: "1.8rem",
          maxWidth: "1.8rem",
          maxHeight: "1.8rem",
          "& svg": {
            width: "100%",
            height: "100%",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        //@ts-ignore
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "inherit",
            borderWidth: 1,
          },
          "& .MuiSelect-select": {
            minWidth: "1.6rem !important",
            paddingRight: "2.4rem !important",
          },
          "& .MuiSelect-icon": {
            right: "0.6rem",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "1.6rem",
          fontWeight: "600",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "0",
          paddingRight: "0.4rem",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderBottom: `1px solid ${getColor("gray300")}`,
          borderRadius: "0 !important",
          "&.Mui-expanded": {
            borderColor: getColor("marineBlue"),
          },
          "&.Mui-expanded .MuiAccordionSummary-content p": {
            fontWeight: 800,
          },
          "&::before": {
            content: "none",
          },
          margin: "0 !important",
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMore sx={{ fontSize: "2.4rem" }} />,
      },
      styleOverrides: {
        root: {
          minHeight: "0 !important",
          padding: "16px",
        },
        content: {
          margin: "0 !important",
        },
      },
    },
  },
});

i18next
  .use(AsyncBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    supportedLngs: ["en", "es"],
    fallbackLng: ["en", "es"],
    ns: ["common"],
    defaultNS: ["common"],
    fallbackNS: ["common"],
    backend: {
      resources: new Proxy(
        {},
        {
          get(_obj, lang: string) {
            return {
              common: () => import(`../../public/locales/${lang}/common.json`),
            };
          },
        }
      ),
    },
  });

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient());
  const { i18n } = useTranslation();
  useEffect(() => {
    const lang = LocalStorage.getItem("hmc_language");

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  useEffect(() => {
    if (isMobile() && typeof screen?.orientation?.lock !== "undefined") {
      screen?.orientation?.lock("landscape");
    }
  }, []);

  return (
    <PlaceHolderProvider placeholderImages={pageProps?.placeholderImages ?? []}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <AlertProvider>
              <AuthProvider>
                <RecoilRoot>
                <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                  <title>Hyundai Newsletter</title>
                </Head>
                <Script
                    // async
                    strategy="afterInteractive"
                    src="https://www.googletagmanager.com/gtag/js?id=G-XLRB7B6X90"
                />
                <Script id="google-analytics">{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent', 'default', {
                  'analytics_storage': 'denied'
                });

                gtag('js', new Date());
                gtag('config', 'G-XLRB7B6X90');
            `}</Script>
                <Component {...pageProps} />
                <CookieAgree />
                </RecoilRoot>
              </AuthProvider>
            </AlertProvider>
          </Hydrate>
        </QueryClientProvider>
      </ThemeProvider>
    </PlaceHolderProvider>
  );
};

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  try {
    if (typeof window !== "undefined") {
      return {};
    }

    let pageProps: any = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const { getPlaiceholder } = await import("plaiceholder");

    const queryClient = new QueryClient();
    const { data } = await getWebzines();
    // console.log(data);

    queryClient.setQueryData(["webzine"], { data });

    const images = await Promise.all(
      data.map(async ({ thumbnail }) => {
        const image = await getPlaiceholder(
          typeof thumbnail === "string" ? thumbnail : thumbnail[ctx?.locale ?? "en"],
          { size: 10 }
        );
        return image;
      })
    );

    return {
      pageProps: {
        ...pageProps,
        placeholderImages: images,
        dehydratedState: dehydrate(queryClient),
      },
    };
  } catch (error) {
    return { pageProps: { error } };
  }
};

export default App;
