class SessionStorage {
  constructor() {}

  static setItem(key: string, item: string) {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(key, item);

      const storageEvent = new StorageEvent("storage", {
        key: key,
        oldValue: this.getItem(key),
        newValue: item,
        url: window.location.href,
        storageArea: sessionStorage,
      });

      window.dispatchEvent(storageEvent);
    }
  }

  static getItem(key: string) {
    if (typeof window !== "undefined") {
      return sessionStorage.getItem(key);
    }
    return null;
  }

  static removeItem(key: string) {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem(key);

      const storageEvent = new StorageEvent("storage", {
        key: key,
        oldValue: this.getItem(key),
        newValue: undefined,
        url: window.location.href,
        storageArea: sessionStorage,
      });

      window.dispatchEvent(storageEvent);
    }
  }
}

export default SessionStorage;
