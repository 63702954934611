import Dialog from "@/components/shared/Dialog";
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { AxiosError } from "axios";

interface Props {
  showAlert: (data: { title: string; content: string }) => void;
}

export const alertContext = createContext<Props>({ showAlert: () => {} });

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [title, setTitle] = useState<string | null>(null);
  const [content, setContent] = useState<string | AxiosError<any> | null>(null);
  const resolveRef = useRef<((value: unknown) => void) | null>(null);

  const showAlert = async ({ title, content }: Parameters<Props["showAlert"]>[0]) => {
    setTitle(title);
    setContent(content);
    setIsShowAlert(true);

    return new Promise((res, _rej) => (resolveRef.current = res));
  };

  const onClose = () => {
    setIsShowAlert(false);
    setTitle(null);
    setContent(null);
  };

  useEffect(() => {
    if (!isShowAlert) {
      resolveRef?.current?.(null);
    }
  }, [isShowAlert]);

  return (
    <alertContext.Provider value={{ showAlert }}>
      {children}
      <Dialog
        open={isShowAlert && title !== null && content !== null}
        title={title ?? ""}
        onClose={onClose}
        buttons={[{ callback: onClose, content: t("close") }]}
      >
        <Box width="480px">
          <Typography fontSize="1.8rem" fontWeight="500">
            {typeof content === "string"
              ? content
              : Array.isArray(content?.response?.data.message)
              ? content?.response?.data.message.join(" ")
              : content?.response?.data.message}
          </Typography>
        </Box>
      </Dialog>
    </alertContext.Provider>
  );
};

export const useAlert = () => useContext(alertContext);
