import { instance } from ".";
import { Article } from "./articles";

export interface Webzine {
  createdAt: number;
  publishedDate: string;
  publishedTime: number;
  _id: string;
  url: string;
  beginPage: number;
  endPage: number;
  title: Record<string, string>;
  pdf: Record<string, string>;
  thumbnail: Record<string, string> | string;
  articles: Article[];
}
export const getWebzines = () => {
  return instance.get<Webzine[]>("/webzines");
};

export const getWebzine = (id: string) => {
  return instance.get<Webzine>(`/webzines/${id}`);
};
