import { Box, DialogProps, Drawer, Dialog as MuiDialog, Typography, useMediaQuery } from "@mui/material";
// import Button from "./Button";
const Button = dynamic(() => import('./Button'), {suspense:true})
import Clear from "@mui/icons-material/Clear";
import { ReactNode } from "react";
import { getColor } from "@/utils/style";
import dynamic from 'next/dynamic';

interface Props {
  title: string;
  onClose: () => void;
  children: ReactNode;
  buttons: {
    content: string;
    color?: string;
    bgcolor?: string;
    callback: () => void;
  }[];
  bottomSheet?: boolean;
}

export default function Dialog({
  title,
  onClose,
  children,
  buttons,
  bottomSheet = false,
  ...props
}: DialogProps & Props) {
  const smMatch = useMediaQuery((theme) => (theme as any)?.breakpoints.down("sm") ?? "",
      { noSsr: true }
  );

  if (smMatch && bottomSheet)
    return (
      <Drawer
        {...props}
        anchor="bottom"
        disablePortal
        onClose={onClose}
        sx={{ zIndex: 1300, "& .MuiPaper-root": { borderRadius: "0", display: "flex", zIndex: 10000 }, ...props.sx }}
      >
        <Box width="100%" height="100%" maxHeight="100%" display="flex" flexDirection="column">
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="2.4rem"
            px="2.4rem"
            borderBottom={`1px solid ${getColor("gray300")}`}
          >
            <Typography fontSize="2.6rem" fontWeight="800" color={getColor("black")}>
              {title}
            </Typography>
            <Button onClick={onClose}>
              <Clear fontSize="large" sx={{ fill: "black" }} />
            </Button>
          </Box>
          <Box
            width="100%"
            height="100%"
            maxHeight="100%"
            p="2.4rem"
            display="flex"
            flexDirection="column"
            bgcolor={getColor("ivory")}
          >
            {children}
            <Box width="100%" display="flex" gap="2rem" mt="5.2rem">
              {buttons.map(({ content, callback, bgcolor, color }) => (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                  py="2rem"
                  bgcolor={bgcolor ?? getColor("marineBlue")}
                  color={color ?? "white"}
                  style={{ cursor: "pointer" }}
                  key={content}
                  onClick={callback}
                >
                  <Typography fontSize="2.2rem" fontWeight="500">
                    {content}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    );

  return (
    <MuiDialog
      {...props}
      disablePortal
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "0",
          display: "flex",
          maxHeight: "calc(100% - 150px - 32px)",
          overflow: "hidden",
        },
        ...props.sx,
      }}
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="2.4rem"
        borderBottom={`1px solid ${getColor("gray300")}`}
        flex="0 1 auto"
        sx={{ p: { xs: "14px", md: "20px" }, py: { xs: "10px", md: "20px" } }}
      >
        <Typography fontSize="2.6rem" fontWeight="800" color={getColor("black")}>
          {title}
        </Typography>
        <Button onClick={onClose}>
          <Clear sx={{ fill: "black", fontSize: "26px" }} />
        </Button>
      </Box>
      <Box
        width="100%"
        height="100%"
        maxHeight="100%"
        p="24px"
        bgcolor={getColor("ivory")}
        overflow="auto"
        sx={{ p: { xs: "14px", md: "20px" }, py: { xs: "10px", md: "20px" } }}
      >
        {children}
        {!!buttons.length && (
          <Box width="100%" display="flex" gap="1.6rem" mt="40px">
            {buttons.map(({ content, callback, bgcolor, color }) => (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignContent="center"
                py="16px"
                bgcolor={bgcolor ?? getColor("marineBlue")}
                color={color ?? "white"}
                style={{ cursor: "pointer" }}
                key={content}
                onClick={callback}
              >
                <Typography fontSize="2rem" fontWeight="500">
                  {content}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </MuiDialog>
  );
}
