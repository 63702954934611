import { AuthInfo, getAuthInfo } from "@/apis/auth";
import SessionStorage from "@/utils/sessionstorage";
import { useRouter } from "next/router";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

interface Props {
  isInitialized: boolean;
  isLoggedIn: boolean;
  logout: () => void;
  refreshInfo: () => void;
  info?: AuthInfo;
}

export const authContext = createContext<Props>({
  isInitialized: false,
  isLoggedIn: false,
  logout: () => {},
  refreshInfo: () => {},
});

// const publicPaths = ["/", "/signin", "/signup", "/forgot-password", "/cookie-preference", "/cookie-terms", "/policy"];

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // const router = useRouter();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [info, setInfo] = useState<AuthInfo>();

  // useEffect(() => {
  //   if (isInitialized && !isLoggedIn && !publicPaths.includes(router.pathname)) {
  //     router.replace(`/signin?fallback=${router.asPath}`);
  //   }
  // }, [isLoggedIn, isInitialized, router.asPath, router.pathname]);

  const logout = () => {
    setIsLoggedIn(false);
    setInfo(undefined);
    SessionStorage.removeItem("hmc_access_token");
    SessionStorage.removeItem("hmc_refresh_token");
  };

  const refreshInfo = async () => {
    const { data } = await getAuthInfo();
    setInfo(data);
  };

  useEffect(() => {
    if (isLoggedIn) {
      refreshInfo();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const onStorage = ({ key, newValue }: StorageEvent) => {
      if (key === "hmc_access_token" && newValue) {
        setIsLoggedIn(true);
      } else if (key === "hmc_access_token" && !newValue) {
        setIsLoggedIn(false);
        logout();
      }
    };

    window.addEventListener("storage", onStorage);

    if (!!SessionStorage.getItem("hmc_access_token")) {
      (async () => {
        try {
          await refreshInfo();
          setIsLoggedIn(true);
        } catch (e) {
          setIsLoggedIn(false);
        } finally {
          setIsInitialized(true);
        }
      })();
    } else {
      setIsInitialized(true);
    }

    return () => window.removeEventListener("storage", onStorage);
  }, []);

  return (
    <authContext.Provider value={{ isLoggedIn, logout, info, refreshInfo, isInitialized }}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);
