import axios from "axios";
import { refresh } from "./auth";
import LocalStorage from "@/utils/localstorage";
import SessionStorage from "@/utils/sessionstorage";
import config from "@/config";

// 임시
// const baseURL = config.isProduction ? "https://api.hyundai-newsletter.com" : "https://api-dev.hyundai-newsletter.com";
const baseURL = "https://api.hyundai-newsletter.com";

export const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const token = SessionStorage.getItem("hmc_access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => {
    console.log("Request Error: ", err);

    return Promise.reject(err);
  }
);

let isRefreshing = false;

instance.interceptors.response.use(
  async (res) => {
    // console.log(res);

    if (res.status === 401 && res.data.message === "Unauthorized") {
      try {
        if (!isRefreshing) {
          isRefreshing = true;
          await refresh();
          isRefreshing = false;
        }
        // return instance(res.config);
      } catch (e) {
        SessionStorage.removeItem("hmc_access_token");
        SessionStorage.removeItem("hmc_refresh_token");
        console.log(e);
      }
    }

    return res;
  },
  async (err) => {
    // console.log("Response Error: ", err);

    if (err.response?.status === 401 && err.response.data.message === "Unauthorized") {
      try {
        if (!isRefreshing) {
          isRefreshing = true;
          await refresh();
          isRefreshing = false;
        }
        // return instance(err.config);
      } catch (e) {
        SessionStorage.removeItem("hmc_access_token");
        SessionStorage.removeItem("hmc_refresh_token");
        // console.log(e);
      }
    }

    return Promise.reject(err);
  }
);
